function userPermission (context, userData, actionName = null) {
  if (userData.user_role == "USERROLE11111") {
    return true
  }

  // Check if User has permission to perform this action
  if (userData.user_permission && JSON.stringify(userData.user_permission).includes(actionName)) {
    return true
  }

  // This is the correct way of access control, in future we will be falling back to this.
  let apiGroup = actionName.split("_")[0] + "*"
  if (userData.user_permission && JSON.stringify(userData.user_permission).includes(apiGroup)) {
    return true
  }

  // This is a wrong way of access control cause the function is generic and we do not have org_id in every component
  if (context.cvOrgId && Object.keys(userData.roles).length > 0 && (userData.roles[context.cvOrgId].udr_name.toLowerCase() === "admin" || userData.roles[context.cvOrgId].udr_name.toLowerCase() === "owner")) {
    return true
  }

  console.error(actionName, JSON.stringify(userData.user_permission).includes(actionName), `Unauthorized access on ${actionName}. Allowed User Actions are` + JSON.stringify(userData.user_permission))
  return false
}

export default userPermission
